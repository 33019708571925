<template>
  <div>
    <window-header></window-header>
    <nav-form :nuevo="false" :eliminar="false"></nav-form>
    <section>
      <article style="height: 365px;">
        <img
          :src="
            formData.imagen && typeof formData.imagen == 'object'
              ? formData.imagen.imagen
              : require('./../assets/noimage.png')
          "
          style="position:absolute;width:52px; height:52px; top: 4px; left:6px;"
        />
        <field
          name="codigo"
          widget="char"
          searchable
          placeholder="Cód."
          width="67px"
          style="top:10px; left:68px;"
          readonly
          inputalign="center"
          fontweight="bold"
        />
        <field
          name="nombre_fiscal"
          searchable
          widget="char"
          help="Razon Social"
          inline-label
          placeholder="Nombre fiscal"
          readonly
          width="220px"
          style="top:10px; left:145px;"
        />
        <field
          name="nombre_comercial"
          searchable
          widget="char"
          help="Nombre Comercial del Cliente"
          required
          placeholder="Nombre comercial"
          width="302px"
          style="top:35px; left:68px;"
          fontweight="bold"
          readonly
        />

        <field
          name="direccion_fiscal"
          searchable
          widget="char"
          help="Direccion Fiscal"
          inline-label
          placeholder="Direccion fiscal"
          width="175px"
          style="top:60px; left:10px;"
          readonly
        />
        <field
          name="direccion_fiscal1"
          searchable
          widget="char"
          help="Direccion Fiscal"
          inline-label
          placeholder="Direccion fiscal"
          width="175px"
          style="top:60px; left:190px;"
          readonly
        />
        <field
          name="cp_fiscal"
          searchable
          widget="char"
          inline-label
          placeholder="CP"
          width="60px"
          style="top:85px; left:10px;"
          readonly
        />
        <field
          name="poblacion_fiscal"
          searchable
          widget="char"
          inline-label
          placeholder="Poblacion"
          width="150px"
          style="top:85px; left:75px;"
          readonly
        />
        <field
          name="provincia_fiscal_id"
          searchable
          widget="m2o"
          inline-label
          placeholder="Provincia"
          width="145px"
          style="top:85px; left:230px;"
          readonly
        />
        <field
          name="telefono_fiscal"
          searchable
          widget="char"
          inline-label
          placeholder="Telefono"
          width="100px"
          style="top:60px; left:250px;"
          readonly
        />

        <field
          name="representante_id"
          searchable
          widget="m2o"
          label="Representante habitual"
          placeholder="Rep."
          required
          width="230px"
          style="top:63px; left:385px;"
          readonly
          imgProp="imagen"
        />

        <field
          ref="documentos"
          name="documentos"
          widget="handsontable"
          :height="209"
          :minRows="1"
          style="top:150px; left:4px; width: 648px;"
          primary="codigo"
          :columns="[
            { name: 'descripcion', header: 'Descripción' },
            {
              name: 'plantilla_id',
              header: 'Plantilla',
              type: 'm2o',
              primary: 'codigo',
              showCode: true
            },
            { name: 'documentos', header: 'Documentos', type: 'files' }
          ]"
        ></field>
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        Listado
        <div class="item-after">{{ count }}</div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<style>
</style>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
import Handsontable from "handsontable";

function floatOrCero(v) {
  v = parseFloat(v || 0);
  if (!isFinite(v)) return 0;
  return v;
}
export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  data: function() {
    var self = this;
    return {
      title: "Documentos de clientes",
      dbAdapter: "cliente",
      primary: "codigo"
    };
  },
  computed: {},
  methods: {
    rendererButtonDescargar(
      instance,
      td,
      row,
      col,
      prop,
      value,
      cellProperties
    ) {
      var self = this;
      Handsontable.renderers.BaseRenderer.apply(this, arguments);
      let $td = window.$(td);
      $td.empty();
      if (instance.getSourceData()[row].id) {
        window
          .$(
            `<button class="button" style="height:1.3em;line-height:0.7;">Descargar</button>`
          )
          .appendTo($td)
          .click(function() {
            self.$utils.misc.downloadFile(
              window.DB.server +
                "documento_cliente/?action=descargarPlantilla&id=" +
                instance.getSourceData()[row].id,
              (instance.getSourceData()[row].descripcion || "documento") +
                ".pdf"
            );
          });
      }

      return $td[0];
    }
  }
};
</script>